/* global jQuery, YT, $ */
const nicWidgets = nicWidgets || {}  // eslint-disable-line no-use-before-define

/**
 * Youtube link widget in nicWidgets namespace.
 *
 * @returns {Youtube} instance of Youtube
 */
nicWidgets.youtube = (function() {

    function dialogExists() {
        // eslint-disable-next-line no-prototype-builtins
        return (typeof jQuery !== 'undefined' && jQuery.hasOwnProperty('ui') && jQuery.ui.hasOwnProperty('dialog'))
    }

    if (!dialogExists()) {
        console.error('Youtube extension requires jQuery.UI')
        return function() {
        }
    }

    if (typeof YT === 'undefined') {
        console.error('Youtube extension requires youtube player api')
        console.error('(http://www.youtube.com/player_api)')
        return function() {
        }
    }

    const originFn = $.ui.dialog.prototype._createOverlay

    jQuery.ui.dialog.prototype._createOverlay = function() {
        originFn.call(this)

        if (this.options['dialogClass'] === 'video-dialog') {
            let overlay = this.overlay
            const that = this
            overlay.on('click', function() {
                // does something like dialog.dialog('close');
                that.close()
                overlay = null
            }).css({
                'background': 'none',
                'background-color': '#111',
                'opacity': '0.85',
            })
        }
    }

    /**
     * All instances of Youtube links are in this array.
     * It is important later to run some functions on all instances, when
     * youtube api is ready.
     *
     * @type Array
     */
    const links = []

    /**
     * Creates specific dialog.
     *
     * @param {type} width width of the video
     * @param {type} height height of the video
     * @param {type} token specific token for each video
     * @param {type} instanceNumber
     * @returns {_L10.dialogFactory.dialog}
     */
    function dialogFactory(token, width, height, instanceNumber) {
        // creates iframe with specific id
        const iframe = $(`
            <iframe
                id="video_${instanceNumber}"
                width="${width}"
                height="${height}"
                src="//www.youtube.com/embed/${token}?enablejsapi=1"
                frameborder="0"
                allowfullscreen
            ></iframe>
        `)

        const dialog = $('<div />')
        dialog.append(iframe)

        dialog.dialog({
            dialogClass: 'video-dialog',
            autoOpen: false,
            modal: true,
            width: width + 60,
            height: height + 60,
            resizable: false,
            show: null,
            hide: null,
            draggable: false,
        })

        // !important detaches close button from dialog and append it to different position
        dialog.parent().find('button.ui-dialog-titlebar-close').detach()
            .prependTo(dialog.parent())
        return dialog
    }

    /**
     * Youtubelink object holds all data about one single link and dialog
     * with it.
     *
     * @param {type} linkEl link element which directs to youtube
     * @param {type} instanceNumber is important when creating dom element with id
     * @returns {_L10.YoutubeLink}
     */
    function YoutubeLink(linkEl, instanceNumber) {
        this.linkEl = linkEl
        this.link = linkEl.attr('href')
        this.instanceNumber = instanceNumber

        // algorithm wich will cut parameters from dom element
        const searchResult = this.link.match(/v=([^&]+)&width=(\d+)&height=(\d+)/)

        if (searchResult !== null && searchResult.length === 4) {
            // create dialog there
            this.dialog = dialogFactory(searchResult[1], searchResult[2] | 0, searchResult[3] | 0, instanceNumber)
            // !important, bind function on element wich will prevent redirect
            $(this.linkEl).click(function(event) {
                event.preventDefault()
                this.dialog.dialog('open')
                return false
            }.bind(this))
        }
    }

    /**
     * Primary object that will run selection.
     *
     * @returns {_L10.Youtube}
     */
    function Youtube() {
        this.instances = 0

        $("a[href*='youtube.com/watch'][href*='width=']")
            .each(function() {
                links.push(new YoutubeLink($(this), ++this.instances))
            })
    }

    /**
     * Called on all instances when youtube api is ready.
     *
     * @returns {undefined}
     */
    YoutubeLink.prototype.onApiReady = function() {
        this.player = new YT.Player('video_' + this.instanceNumber, {
            events: {
                // call this function when player is ready to use
                'onReady': this.onPlayerReady.bind(this),
            },
        })
    }

    /**
     * Called when player is ready, so youtube video is
     * automaticaly paused when dialog is closed.
     *
     * @returns {undefined}
     */
    YoutubeLink.prototype.onPlayerReady = function() {
        const player = this.player

        this.dialog.on('dialogclose', function() {
            player.pauseVideo()
        })
    }

    /**
     * Youtube api is looking for this function. It must be part of window.
     *
     * @returns {undefined}
     */
    window.onYouTubeIframeAPIReady = function() {
        for (const i in links) {
            links[i].onApiReady()
        }
    }

    return Youtube
})()
